import React, { useState } from 'react'
import fetch from 'isomorphic-unfetch'

import { Button } from './Button'
import { Modal } from './Modal'

import { encodeFormData, validateEmail } from '../helpers'

const ContactForm = ({ globalEmail }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState({
    name: null,
    email: null,
    message: null,
  })
  const [modal, setModal] = useState()

  function handleChange(type, value) {
    switch (type) {
      case 'name':
        if (error.name) setError(error => ({ ...error, name: null }))
        setName(value)
        break
      case 'email':
        if (error.email) setError(error => ({ ...error, email: null }))
        setEmail(value)
        break
      case 'message':
        if (error.message) setError(error => ({ ...error, message: null }))
        setMessage(value)
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()

    let hasError = false

    if (!name.trim().length) {
      setError(current => ({ ...current, name: 'Please provide your name.' }))
      hasError = true
    }

    if (!message.trim().length) {
      setError(current => ({ ...current, message: 'Please provide a message.' }))
      hasError = true
    }

    if (!email.trim().length) {
      setError(current => ({ ...current, email: 'Please provide a email.' }))
      hasError = true
    }

    if (!validateEmail(email)) {
      setError(current => ({ ...current, email: 'Please provide a valid email address.' }))
      hasError = true
    }

    if (hasError) return

    const data = { 'form-name': 'contact', name, email, message, subject: 'Captain Ukuleles website enquiry' }

    try {
      await fetch('/', {
        method: 'POST',
        body: encodeFormData(data),
      })
      setModal('sent')
    } catch (e) {
      setModal('failed')
    }
  }

  function handleSentClose() {
    setName('')
    setEmail('')
    setMessage('')
    setModal()
  }

  return (
    <>
      <form className="w-full text-left" name="contact" data-netlify="true" action="/contact?message=sent">
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2">
            <label className="block uppercase text-sm font-heading mb-2" htmlFor="name">
              Name
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 border rounded py-3 px-4 mb-2 focus:outline-none focus:border-gray-500 ${
                error.name ? 'border-red' : 'border-gray-200'
              }`}
              id="name"
              name="name"
              type="text"
              placeholder="Your Name"
              onChange={e => handleChange('name', e.target.value)}
              value={name}
            />
            {error.name && <p className="text-red-500 text-xs italic">{error.name}</p>}
          </div>
          <div className="w-full md:w-1/2 px-2 mt-2 md:mt-0">
            <label className="block uppercase text-sm font-heading mb-2" htmlFor="email">
              Email Address
            </label>
            <input
              className={`appearance-none block w-full bg-gray-200 border rounded py-3 px-4 mb-2 focus:outline-none focus:border-gray-500 ${
                error.email ? 'border-red' : 'border-gray-200'
              }`}
              id="email"
              type="email"
              name="email"
              placeholder="Enter your email"
              onChange={e => handleChange('email', e.target.value)}
              value={email}
            />
            {error.email && <p className="text-red-500 text-xs italic">{error.email}</p>}
          </div>
        </div>
        <div className="flex flex-wrap mt-2">
          <div className="w-full">
            <label className="block uppercase text-sm font-heading mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              className={`appearance-none block w-full bg-gray-200 border rounded py-3 px-4 mb-2 focus:outline-none focus:border-gray-500 ${
                error.message ? 'border-red' : 'border-gray-200'
              }`}
              id="message"
              name="message"
              placeholder="Enter your message"
              rows={8}
              onChange={e => handleChange('message', e.target.value)}
              value={message}
            />
            {error.message && <p className="text-red-500 text-xs italic">{error.message}</p>}
          </div>
          <div className="mt-4">
            <Button type="submit" onClick={handleSubmit}>
              Send
            </Button>
          </div>
        </div>
      </form>
      {modal && (
        <Modal>
          <div className="p-4 max-h-full h-screen flex items-center justify-center relative">
            <div className="w-full max-w-sm p-8 rounded bg-white text-center">
              <p className="text-3xl">{modal === 'sent' ? 'Message sent!' : 'Message failed to send.'}</p>
              <p className="mt-4">
                {modal === 'sent' ? "We'll be in touch soon." : `Please try again or email ${globalEmail}`}
              </p>
              <Button className="mt-4 cursor-pointer" onClick={handleSentClose}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export { ContactForm }

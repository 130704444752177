import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { ContactForm } from '../components/ContactForm'

import { toContactData } from '../transform'

const Contact = ({
  data: {
    prismic: { contact_page, global_fields },
  },
}) => {
  const { email, questions, questionsTitle, title } = toContactData({ ...contact_page, ...global_fields })

  return (
    <Layout>
      <SEO title={RichText.asText(title)} />
      <div id="contact" className="bg-white text-left">
        <section className="px-4 py-8 md:py-12 lg:py-16">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-5xl md:text-6xl">{RichText.asText(title)}</h1>
            <div className="mt-2 md:mt-4">
              <ContactForm globalEmail={email} />
            </div>
          </div>
        </section>
        <section className="bg-gray-200 px-4 py-8 md:py-12 lg:py-16">
          <div className="max-w-3xl mx-auto">
            <p className="text-3xl capitalize font-heading">{RichText.asText(questionsTitle)}</p>
            {questions.map(({ answer, question }) => (
              <div key={RichText.asText(question)} className="mt-8">
                <p className="font-bold">{RichText.asText(question)}</p>
                <div className="mt-2">
                  <RichText render={answer} />
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      global_fields(lang: "en-nz", uid: "global") {
        email_address
      }
      contact_page(lang: "en-nz", uid: "contact") {
        ...contactPage
      }
    }
  }
`

export default Contact
